

















































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { vxm } from '@/store'
import CostAccountingRuleService from '@/services/economy/CostAccountingRuleService'
import CostAccountingRule from '@/models/economy/CostAccountingRule'
import AccountService from '@/services/economy/AccountService'
import Account from '@/models/economy/Account'
import { axios } from '@/plugins/vueaxios'
import ProductType from '@/models/product/ProductType'

@Component({})
export default class Edit extends Vue {
  private isSaving = false
  private rules = {}
  private data: CostAccountingRule = null
  private error = ''
  private accounts = []
  private productTypes = []

  public created(): void {
    this.data = new CostAccountingRule()
    this.rules = {
      required: [(v) => !!v || this.$t('c:validation:This field is required')],
    }
    this.load()
  }

  private get id(): number {
    return this.$route.params.id ? parseInt(this.$route.params.id) : null
  }

  private get isNew() {
    return !!this.$route.meta.isNew
  }

  private load() {
    this.loadProductTypes().then((types) => {
      this.productTypes = types
      AccountService.loadAll()
        .then((accounts) => {
          this.accounts = accounts
          if (!this.isNew) {
            CostAccountingRuleService.loadOne(this.id).then((item) => {
              this.data = item
            })
          }
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err)
        })
    })
  }

  private loadProductTypes(): Promise<ProductType[]> {
    return new Promise((resolve, reject) => {
      return axios
        .get('/v4/site/products/types?includeShop=1&legacy=1&perPage=500')
        .then((response) => {
          const result = []
          response.data.data.forEach((v) => {
            result.push(new ProductType(v))
          })
          resolve(result)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  private get form() {
    return this.$refs.form as Vue & { validate: () => boolean }
  }

  private clickSave() {
    if (!this.form.validate()) {
      return
    }
    this.error = ''
    if (this.isNew) {
      CostAccountingRuleService.create(this.data)
        .then((_response) => {
          this.$router.back()
        })
        .catch((err) => {
          err.prefix = 'Could not save cost accounting rule'
          vxm.alert.onAxiosError(err)
        })
    } else {
      this.data.id = this.id
      CostAccountingRuleService.update(this.data)
        .then((_response) => {
          this.$router.back()
        })
        .catch((err) => {
          err.prefix = 'Could not save cost accounting rule'
          vxm.alert.onAxiosError(err)
        })
    }
  }

  private clickCancel() {
    this.$router.back()
  }

  private get costAccounts(): Account[] {
    const result = []
    this.accounts.forEach((a) => {
      if (a.isTypeCost) {
        result.push(a)
      }
    })
    return result
  }
}
