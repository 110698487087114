export default class CostAccountingRule {
  public id: number
  public accountId: number
  public productTypeId: number
  public isDefault: boolean

  public constructor(data: Record<string, unknown> = null) {
    if (!data) {
      data = {}
    }
    this.id = data.id ? (data.id as number) : null
    this.accountId = data.accountId ? (data.accountId as number) : null
    this.productTypeId = data.productTypeId ? (data.productTypeId as number) : null
    this.isDefault = data.productTypeId ? false : !!data.id
  }

  public clone(): CostAccountingRule {
    const result = new CostAccountingRule()
    Object.assign(result, this)
    return result
  }
}
